<template>
  <label @click="startUpload">
    <slot></slot>
  </label>
</template>

-------------------------------------
<script>
    export default {

        data() {
            return {
                id: null
            }
        },

        mounted() {
            this.reCreateInput();
        },


        methods: {
            // 开始上传
            startUpload() {
                const input = document.getElementById(this.id);
                input.click();
            },


            // 重新创建一个用于上传的input
            reCreateInput() {
                if (this.id) {
                    const oldInput = document.getElementById(this.id);
                    oldInput.parentElement.removeChild(oldInput);
                }
                this.id = Date.now() + (Math.random().toFixed(3) * 1000);
                const input = document.createElement('input');
                input.type = 'file';
                input.style.display = 'none';
                input.id = this.id;
                input.oninput = (e) => {
                    this.onFileChange(e);
                };
                document.body.appendChild(input);
            },

            // 文件变化
            onFileChange(e) {
                const {files} = e.target;
                if (files && files.length) {
                    this.$emit('change', files);
                }
                this.reCreateInput();
            }
        }

    };
</script>

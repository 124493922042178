<template>
  <div class="container infoMsg">

    <v-header></v-header>

    <div class="scroll-container">
      <div class="header">
        <div class="box">
          <div class="avatar_box">
            <!--<van-uplaoder :after-read="changeTitle">-->
            <!--<avatar :src="userInfo.photoResourceUrl"></avatar>-->
            <!--</van-uplaoder>-->
            <van-uploader :after-read="changeTitle">
              <avatar :src="userInfo.photoResourceUrl"></avatar>
            </van-uploader>
          </div>
          <span class="user_name">{{ userInfo.username }}</span>
        </div>
      </div>

      <div class="gray"></div>

      <div class="info_detail">
        <div class="info_detail_cell">
          <div class="detail_cell">
            <div class="label"><span class="span">用户昵称</span></div>
            <div class="value">
              <input v-model="params.username" placeholder="请输入用户昵称" maxlength="8" style="padding-left: 0"/>
            </div>
          </div>
          <div class="detail_cell">
            <div class="label"><span class="span">性<span style="color: #fff;">性别</span>别</span></div>
            <div class="value">
              <van-radio-group v-model="params.sex" direction="horizontal">
                <van-radio name="男">
                  <template #icon="props">
                    <i class="iconfont" :class="props.checked ? 'icon-checked' : 'icon-radio'"></i>
                  </template>
                  男
                </van-radio>
                <van-radio name="女">
                  女
                  <template #icon="props">
                    <i class="iconfont" :class="props.checked ? 'icon-checked' : 'icon-radio'"></i>
                  </template>
                </van-radio>
                <van-radio name="保密">
                  保密
                  <template #icon="props">
                    <i class="iconfont" :class="props.checked ? 'icon-checked' : 'icon-radio'"></i>
                  </template>
                </van-radio>
              </van-radio-group>
            </div>
          </div>

          <div class="detail_cell">
            <div class="label"><span class="span">所在城市</span></div>
            <div class="value hasInput">
              <input @click="showArea = true" type="text" readonly placeholder="请选择省/市" v-model="params.city">
              <van-icon name="arrow-down" :class="showArea && 'show'"/>
            </div>
          </div>

          <div class="detail_cell">
            <div class="label"><span class="span">职业/领域</span></div>
            <div class="value hasInput">


              <van-popover
                placement="bottom-start"
                @select="select"
                v-model="showPopover"
                trigger="click"
                :actions="profession">
                <template #reference>
                                <span class="popover-title">
                                    <input v-model="params.profession" placeholder="请选择"/>
                                    <van-icon name="arrow-down" :class="showPopover && 'show'"/>
                                </span>
                </template>
              </van-popover>
            </div>
          </div>

          <div class="detail_cell">
            <div class="label" style="width: 100%"><span class="span">一句话简介 <span class="desc">50字以内</span></span></div>

          </div>
          <div class="detail_cell" style="padding-top: 0;">
            <div class="value hasInput">
              <input v-model="params.introduction" placeholder="50字以内" maxlength="50"/>
            </div>
          </div>


          <div class="detail_cell">
            <div class="label" style="width: 100%"><span class="span">技能关键词 </span><span class="desc">5个以内</span></div>
          </div>
          <div class="tag_wrap">
            <template v-for="(i, index) in tags">
              <div class="new_tag">{{ i }} <span class="close" @click="removeOne(index)">×</span></div>
            </template>

            <div class="create_tag" @click="addTag" v-if="tags && tags.length <= 4">创建新标签</div>
          </div>

        </div>


        <div class="submitBtn btn">
          <div class="button" @click="save"><span class="txt">保存</span></div>
        </div>

      </div>
    </div>


    <van-popup v-model="showArea" round position="bottom">
      <van-area
        @cancel="showArea = false"
        @confirm="confirmArea"
        :columns-num="2"
        title="所在城市"
        :area-list="areaList"
        :value="city"
      ></van-area>
    </van-popup>


    <modal ref="modal">
      <div class="model_content">
        <span class="line1_txt">添加标签</span>
        <span class="small_txt">
                    <div style="height: 30px;width: 100%;padding: 0 10px;border-radius: 5px;border: 1px solid #efefef;">
                        <input type="text" v-model="tag" placeholder="请输入标签" maxlength="5"
                               style="height: 30px;width: 100%;border-radius: 5px;outline: none;box-shadow: none;border: none">
                    </div>
                </span>
        <div class="btn" @click="submitTag"><span class="text">确定</span></div>
      </div>
    </modal>


  </div>
</template>

<script>
import modal from '@/components/model/model'
import avatar from '@/components/avatar'
import {mapGetters, mapMutations} from 'vuex'
import {user} from "@/api";
import {taskFinishRecordV2Api} from "../../api/v2/taskFinishRecordV2Api";
import MyUplaod from '@/components/myUpload'
import {userV2Api} from "../../api/v2/userV2Api";
import {sleep} from "../../config/util";

export default {
  components: {modal, avatar, MyUplaod},
  computed: {...mapGetters(['userInfo'])},
  data() {
    return {
      showArea: false,
      showPopover: false,
      areaList: areaList,
      profession: [
        {text: '文案/策划'},
        {text: '创意/设计'},
        {text: '品牌/营销'},
        {text: '客户/市场'},
        {text: '运营/产品'},
        {text: '项目/执行'},
        {text: '制作/技术'},
        {text: '组织/文化'},
        {text: '教育/研究'},
        {text: '其他'}],
      tags: [],
      tag: "",
      city: '',
      params: {
        username: '',
        sex: '',
        city: '',
        profession: '',
        introduction: '',
        tags: '',
        photoResourceId: ''
      }
    }
  },
  created() {
    this.setTags();
    // console.log(this.userInfo);
    this.params = {
      username: this.userInfo.username,
      sex: this.userInfo.sex,
      city: this.userInfo.city,
      introduction: this.userInfo.introduction,
      profession: this.userInfo.profession,
      tags: this.userInfo.tags,
      photoResourceId: this.userInfo.photoResourceId
    };

    try {
      this.city = this.userInfo.city;
    } catch (e) {

    }
  },
  methods: {
    ...mapMutations(["SET_USERINFO"]),
    //设置标签
    setTags() {
      try {
        const tags = this.userInfo.tags;
        this.tags = Array.isArray(tags) ? tags : tags.split(',');
      } catch (e) {

      }
    },
    select(val) {
      // console.log(val);
      this.params.profession = val.text;
    },
    confirmArea(val) {
      let area = val.filter(i => i);
      this.params.city = area.map(item => item.name).join(',');
      this.showArea = false;
    },


    //添加标签
    addTag() {
      this.$refs['modal'].toggleDialog();
    },

    removeOne(index) {
      this.tags.splice(index, 1);
    },


    submitTag() {
      this.tags.push(this.tag);
      this.$refs['modal'].toggleDialog();
      this.tag = '';
    },


    //修改头像
    async changeTitle({file}) {
      var formData = new FormData();
      formData.append('file', file);
      const {code, data} = await user.upload(formData);
      if (code != 1) {
        return self.$toast.error('上传失败');
      } else {
      }
      this.userInfo.photoResourceUrl = data.fullPath;
      //this.SET_USERINFO(self.userInfo);
      this.params.photoResourceId = data.id;
    },

    async save() {
      this.params.tags = this.tags.join(',');
      this.$loading();
      try {
        await userV2Api.updateMy(this.params)
        await sleep(2000)
        this.$toast.success('修改成功');
        const res = await user.info();
        this.SET_USERINFO(res.data);
        await taskFinishRecordV2Api.notifyByTaskId(2);
      } catch (e) {

      } finally {
        this.$loading.close();
      }


    }
  }
}
</script>

<style scoped lang="less">
/* pages/personal/center/editInfo/index.wxss */
.infoMsg {
  background-color: #fff;
}

.infoMsg .header {
  padding: 20px;
  display: flex;
  justify-content: center;

}

.infoMsg .header .box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.avatar_box {
  width: 80px;
  height: 80px;
  min-height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.box .avatar {
  width: 100%;
  height: 100%;
}

.user_name {
  margin-top: 10px;
  line-height: 150%;
  font-size: 12px;
  font-weight: bold;
}

.gray {
  background-color: #f7f7f7;
  height: 15px;
}

/* 个人信息详情 */
.info_detail {
  padding: 27px;
}

.info_detail_cell {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.detail_cell {
  display: flex;
  padding: 10px 0;
}

.detail_cell .label {
  width: 75px;
  height: 32px;
  line-height: 32px;
}

.detail_cell .label .desc {
  color: var(--grey-color);
  font-weight: normal;
}

.detail_cell .label .span {
  font-weight: bold;
  font-size: 14px;
}

.detail_cell .value {
  flex: 1;
  display: flex;

  i {
    font-size: 18px;
  }
}

.detail_cell .value .span {
  font-size: 14px;
  line-height: 150%;
}

.detail_cell .value .radio {
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-group {
  display: flex;
  flex-direction: row;
}


.detail_cell .value.hasInput {
  align-items: flex-start;
  position: relative;
  background: #f4f4f4;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
}

.detail_cell .value.hasInput i {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 12px;
  margin-top: -6px;
  color: var(--grey-color);
  transition: all ease .3s;

  &.show {
    transform: rotate(180deg);
  }
}

.detail_cell .value input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding-left: 10px;
  background: transparent;
}

.detail_cell .value .textarea {
  width: 100%;
  height: 75px;
  /* border: 1px solid #ddd; */
}

.detail_cell .title {
  height: 25px;
  line-height: 25px;
}

.detail_cell .title .text {
  font-weight: bold;
  font-size: 14px;
}


/* 创建新标签 */
.tag_wrap {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.tag_wrap .new_tag {
  display: flex;
  align-items: center;
  color: rgb(80, 80, 80);
  padding: 5px 15px;
  font-size: 14px;
  background-color: #f7f7f7;
  border-radius: 13px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.tag_wrap .new_tag .close {
  margin-left: 10px;
}

.tag_wrap .create_tag {
  color: rgb(80, 80, 80);
  background-color: #fff;
  border-radius: 13px;
  border: 1px solid #151d36;
  display: flex;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.submitBtn {
  padding: 0 30px;
  display: flex;
}

.submitBtn .button {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: #151d36;
  border-radius: 23px;
  padding: 10px 0;
}

.submitBtn .button .txt {
  color: #fff;
  font-size: 16px;
}

.hasTextarea textarea {
  width: 100%;
  border: none;
  outline: none;
  line-height: 150%;
}


</style>
